import axios from "./../axios.js";
import {
    newUrl
} from "./../baseUrl.js";

function newApi(data) {
    return axios.request({
        ...data,
        baseURL: newUrl
    })
}

export function getCustomer(params) {
    return newApi({
        method: 'GET',
        url: '/cust/customer/list',
        params
    })
}


export function getDetailList(params) {
    return axios.request({
        url: '/cust/customer-order/statistic?customerCode='+params,
        baseURL: newUrl
    })
}

export function listFollowrecord(query) {
    return newApi({
        url: '/cust/followrecord/list?customerId='+query.customerId+'&followtype='+query.followtype,
        method: 'get'
    })
}

export function listAllMember(query) {
    return newApi({
        url: '/cust/user/listAll?customerId='+query,
        method: 'get'
    })
}

export function getList(query,start,end) {
    return newApi({
        url:'/cust/customer-order/sales-trend?customerCode='+query+"&startDate="+start+"&endDate="+end,
        method:'get'
    })
}

export function getMidTwo(p1,p2) {
    return newApi({
        url: '/cust/customer-order/distribution?customerCode='+p1+"&dimension="+p2,
        method: 'get'
    })
}

export function getCustomerDetail(id) {
    return newApi({
        url: `/cust/customer/detail/${id}`,
        method: 'get'
    })
}

export function getTableList(pageNum,pageSize,customerName) {
    return newApi({
        url: '/marketing/morelinkorder/list?pageNum='+pageNum+"&pageSize="+pageSize+"&customerName="+customerName,
        method: 'get'
    })
}

export function selecttransit(pageNum,pageSize,customerName) {
    return newApi({
        url: '/wms/warehouse/selecttransit',
        method: 'get'
    })
}

// 上传文件通用
export function uploadFile(data) {
    return newApi({
        url: '/common/upload',
        method: 'post',
        data: data
    })
}

export function getChannelList() {
    return newApi({
        url: '/channel/list',
        method: 'get'
    })
}

export function getChannelPageList(params) {
    return newApi({
        url: '/channel/page',
        method: 'get',
        params
    })
}

export function getChannelInfo(id) {
    return newApi({
        url: '/channel/'+id,
        method: 'get'
    })
}

export function getOrderCalculateApplyList(params) {
    return newApi({
        url: '/order-calculate-apply/result/list?channelName='+params.channelName+'&customerName='+params.customerName+"&pageNum="+1+"&pageSize="+10+'&sono='+params.sono,
        method: 'get'
    })
}

export function getOrderCalculateApplyList2(params) {
    return newApi({
        url: '/order-calculate-apply/result/list',
        method: 'get',
        params
    })
}

export function addOrderCalculateApply(data) {
    return newApi({
        url: '/order-calculate-apply/add',
        method: 'post',
        data:data
    })
}

export function editOrderCalculateApply(data) {
    return newApi({
        url: '/order-calculate-apply/update',
        method: 'post',
        data:data
    })
}


export function delDetailById(params) {
    return newApi({
        url: '/order-calculate-apply/'+params,
        method: 'delete'
    })
}

export function getDetailById(params) {
    return newApi({
        url: '/order-calculate-apply/'+params,
        method: 'get'
    })
}

export function getChargedWeightApplyList(params) {
    return newApi({
        url: '/order-calculate-apply/charged_weight_apply/list?customerName='+params.customerName+'&sono='+params.sono,
        method: 'get'
    })
}

export function ListAddtionfee() {
    return newApi({
        url: '/extra-charge/page-list?pageNum=1&pageSize=100',
        method: 'get',
    })
}

export function getFeeApplyList(sono) {
    return newApi({
        url: '/order-calculate-apply/fee_reduction_apply/list?sono='+sono,
        method: 'get'
    })
}

export function getPublicly(params) {
    return newApi({
        method: 'GET',
        url: '/cust/customer/listpublicly',
        params
    })
}

export function getUnallocatedUserList(params) {
    return newApi({
        method: 'GET',
        url: '/cust/customer/unallocatedUserList',
        params
    })
}

export function selectSaleUser(data) {
    return newApi({
        url: '/cust/customer/selectSaleUser',
        method: 'post',
        data: data
    })
}

export function setBlacklist(data) {
    return newApi({
        url: '/cust/customer/setblacklist',
        method: 'POST',
        data: data
    })
}

export function selectBatchSaleUser(data) {
    return newApi({
        url: '/cust/customer/selectBatchSaleUser',
        method: 'post',
        data: data
    })
}

export function getCluePubList(params) {
    return newApi({
        method: 'GET',
        url: '/cust/customer/listcluepub',
        params
    })
}

export function getClueList(params) {
    return newApi({
        method: 'GET',
        url: '/cust/customer/listclue',
        params
    })
}

export function releaseSaleUser(data) {
    return newApi({
        method: 'POST',
        url: '/cust/customer/releaseSaleUser',
        data: data
    })
}

export function listUser(params) {
    return newApi({
        url: '/system/user/list',
        method: 'get',
        params
    })
}

export function selectFBA(params) {
    return newApi({
        url: '/wms/warehouse/selectFBA',
        method: 'get',
        params
    })
}

export function getDicts(dictType) {
    return newApi({
        url: '/system/dict/data/type/' + dictType,
        method: 'get'
    })
}

export function getUserProfile(params) {
    return newApi({
        url: '/system/user/profile',
        method: 'get'
    })
}

export function getProcessHisDealt(params) {
    return newApi({
        url: '/process/his/listDealt',
        method: 'get',
        params
    })
}

export function getProcessHisApply(params) {
    return newApi({
        url: '/process/his/listApply',
        method: 'get',
        params
    })
}

export function getAuthRouters(params) {
    return newApi({
        url: '/getRouters',
        method: 'get',
        params
    })
}

export function getProductCategoryInquire(params) {
    return newApi({
        url: '/productCategoryInquire/page',
        method: 'get',
        params
    })
}

export function getCountryList(params) {
    return newApi({
        url: '/system/country/list',
        method: 'get',
        params
    })
}

export function getProductCategoryInquireOne(data) {
    return newApi({
        method: 'POST',
        url: '/productCategoryInquire/step_one',
        data: data
    })
}

export function getProductCategoryInquireTwo(data) {
    return newApi({
        method: 'POST',
        url: '/productCategoryInquire/step_two',
        data: data
    })
}

export function getProductCategoryInquireTwo2(data) {
    return newApi({
        method: 'POST',
        url: '/productCategoryInquire/step_two/order',
        data: data
    })
}

export function addProductCategoryInquire(data) {
    return newApi({
        method: 'POST',
        url: '/productCategoryInquire',
        data: data
    })
}

export function getProductCategoryInquireById(id) {
    return newApi({
        url: '/productCategoryInquire/' + id,
        method: 'get'
    })
}

export function confirmProductCategoryInquiryReply(id,data) {
    return newApi({
        url: '/productCategoryInquire/reply/' + id,
        method: 'post',
        data:data
    })
}

export function confirmProductCategoryInquiryReplyAndUpdate(id,data) {
    return newApi({
        url: '/productCategoryInquire/replyAndUpdate/' + id,
        method: 'post',
        data:data
    })
}

export function getProductCategory(params) {
    return newApi({
        url: '/productCategory/page',
        method: 'get',
        params
    })
}

export function addProductCategoryCustomsBusiness(data) {
    return newApi({
        method: 'POST',
        url: '/productCategoryCustomsBusiness',
        data: data
    })
}

export function addProductCategoryKeyword(data) {
    return newApi({
        method: 'POST',
        url: '/productCategoryKeyword',
        data: data
    })
}

export function editProductCategoryCustomsBusiness(data,id) {
    return newApi({
        url: '/productCategoryCustomsBusiness/' + id,
        method: 'patch',
        data:data
    })
}

export function addPathVisit(data) {
    return newApi({
        method: 'POST',
        url: '/pathVisit',
        data: data
    })
}

export function getPathVisitTop(params) {
    return newApi({
        url: '/pathVisit/top',
        method: 'get',
        params
    })
}

export function getCustomerNearByList(params) {
    return newApi({
        url: '/cust/customer/nearbyCustomerList',
        method: 'get',
        params
    })
}

export function getOutsideApprovalRecord(params) {
    return newApi({
        url: '/outsideApprovalRecord/page',
        method: 'get',
        params
    })
}

export function getOutsideApprovalRecordById(id) {
    return newApi({
        url: '/outsideApprovalRecord/'+id,
        method: 'get'
    })
}

export function getOutsideApprovalRecordBySpNo(spNo) {
    return newApi({
        url: '/outsideApprovalRecord/checkinRecord/'+spNo,
        method: 'get'
    })
}

export function getCustomerInfo(params) {
    return newApi({
        url: '/cust/customer/list',
        method: 'get',
        params
    })
}

export function addOutSideFollowRecord(id,data) {
    return newApi({
        method: 'POST',
        url: '/outsideApprovalRecord/addOutsideFollowRecord/'+id,
        data: data
    })
}

export function addOutSideApprovalRecord(data) {
    return newApi({
        method: 'POST',
        url: '/outsideApprovalRecord/checkin',
        data: data
    })
}

export function listCustomerAllPro(params) {
    return newApi({
        url: '/customerInfo/choose/list',
        method: 'get',
        params
    })
}

export function getCustomerInfoPage(params) {
    return newApi({
        url: '/customerInfo/page',
        method: 'get',
        params
    })
}